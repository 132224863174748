import React, { type ReactNode, useState } from 'react'
import { Button, ButtonGroup } from '@mui/material'
import { countries } from 'config/countries'
import type { HandleSubmitProps, MarketFormFieldsProps, TabName } from './MarketForm.types'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Codes from './Codes'
import Info from './Info'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'

const MarketFormFields = ({ isAddForm, languagesArray, continents, marketLanguages, onHandleSubmit, defaultValue }: MarketFormFieldsProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<TabName>('info')
  const { t } = useTranslation()

  const defaultValues: HandleSubmitProps = {
    name: countries.find(el => el.label === defaultValue?.name) ?? { label: '', code: '' },
    description: defaultValue?.description ?? '',
    languages: marketLanguages?.map(el => ({ key: el.code, name: el.name, nativeName: el.nativeName })) ?? [],
    timeZone: defaultValue?.timeZone ?? '',
    groupId: defaultValue?.groupId !== undefined ? defaultValue.groupId.toString() : '',
    codes: defaultValue?.codes !== undefined ? defaultValue.codes : []
  }

  const validationSchema = Yup.object().shape({
    name: Yup.object({ code: Yup.string(), label: Yup.string() }).required(t('forms.required', { field: t('market.name') }) ?? 'Required')
      .test({
        message: t('forms.required', { field: t('market.name') }) ?? 'Required',
        test: field => field?.code !== undefined && field?.code !== undefined && field?.code !== '' && field?.code !== ''
      }),
    timeZone: Yup.string().required(t('forms.required', { field: t('market.timezone') }) ?? 'Required'),
    languages: Yup.array().test({
      message: 'You must select min 1 language per market',
      test: arr => arr?.length !== undefined ? arr?.length > 0 : false
    }),
    groupId: Yup.string().required(t('forms.required', { field: t('market.group') }) ?? 'Required')
  })

  const { handleSubmit, control, trigger } = useForm<HandleSubmitProps>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  const renderTab = (): JSX.Element => {
    switch (selectedTab) {
      case 'info': {
        return <Info isAddForm={isAddForm} control={control} languagesArray={languagesArray} continents={continents} />
      }
      case 'codes': {
        return <Codes control={control} />
      }
    }
  }

  const changeTab = async (name: TabName): Promise<void> => {
    await trigger().then(isValid => {
      if (isValid) {
        setSelectedTab(name)
      }
    })
  }

  const navBtns = (): ReactNode => (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      {selectedTab === 'codes'
        ? <Button
          type='button'
          variant='contained'
          sx={{ ml: 2 }}
          onClick={async () => { await changeTab('info') }}
        >
          {t('common.prev')}
        </Button>
        : null}
      {selectedTab === 'info'
        ? <Button
          type='button'
          variant='contained'
          sx={{ ml: 2 }}
          onClick={async () => { await changeTab('codes') }}
        >
          {t('common.next')}
        </Button>
        : null}
      {selectedTab === 'codes' || !isAddForm
        ? <Button
          type='submit'
          variant='contained'
          sx={{ ml: 2 }}
          startIcon={<DoneOutlinedIcon />}
        >
          {t('common.save')}
        </Button>
        : null}
    </Box>
  )

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)} noValidate>
      <ButtonGroup sx={{ mb: 3 }}>
        <Button variant={selectedTab === 'info' ? 'contained' : 'outlined'} onClick={async () => { await changeTab('info') }}>
          {t('market.config')}
        </Button>
        <Button variant={selectedTab === 'codes' ? 'contained' : 'outlined'} onClick={async () => { await changeTab('codes') }}>
          {t('market.codes')}
        </Button>
      </ButtonGroup>
      {navBtns()}
      {renderTab()}
      {navBtns()}

    </form>
  )
}

export default MarketFormFields
