import React, { useEffect, useState } from 'react'
import TableRow from '@mui/material/TableRow'
import { type Promotion } from './Promotions.types'
import AdvancedTable from 'components/AdvancedTable'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'
import { renderDate } from 'utils/renderDate'
import { renderCells } from 'utils/renderCells'
import { Can } from 'config/ability'
import { useNavigate } from 'react-router-dom'
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined'
import ActionDropdown from 'components/ActionDropdown'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ConfirmDialog from 'components/ConfirmDialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosDelete } from 'connectors/axiosDelete'
import { useAuthContext } from 'context/AuthContext'
import { useAlert } from 'context/AlertContext'
import { ImportPromotions } from './import-promotions'
import Link from '@mui/material/Link'

const Promotions = (): JSX.Element => {
  const [elementToDelete, setElementToDelete] = useState<Promotion | null>(null)
  const { token, refreshToken } = useAuthContext()
  const queryClient = useQueryClient()
  const { changeMessage } = useAlert()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { mutate: deleteMutate, data: deleteStatusResponse } = useMutation(
    async (id: number) => await axiosDelete(token, refreshToken, `promotions/${id}`),
    {
      onSuccess: (response) => {
        if (response !== undefined && !('error' in response)) {
          void queryClient.refetchQueries(['Promotions-query'])
        }
      }
    }
  )

  useEffect(() => {
    if (typeof deleteStatusResponse !== 'string' && deleteStatusResponse !== undefined && 'error' in deleteStatusResponse) {
      changeMessage(deleteStatusResponse?.error?.data?.message, 'error', () => { })
    }
    if (typeof deleteStatusResponse !== 'string' && deleteStatusResponse !== undefined && !('error' in deleteStatusResponse)) {
      changeMessage(t('common.success'), 'success', () => { })
      void queryClient.refetchQueries(['Promotions-query'])
    }
  }, [deleteStatusResponse])

  const handleHideDialog = (): void => {
    setElementToDelete(null)
  }

  const handleDelete = (): void => {
    if (elementToDelete !== null) {
      deleteMutate(elementToDelete.id)
      setElementToDelete(null)
    }
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4' component='h1' mb={2}>{t('navigation.promotions')}</Typography>
        <Box>
          <ImportPromotions />
          <Can I='create' a='Promotions'>
            <Button variant='contained' onClick={(): void => { navigate('/promotions/add') }} startIcon={<PlaylistAddOutlinedIcon />}>{t('common.add')}</Button>
          </Can>
        </Box>

      </Box>
      <AdvancedTable<Promotion>
        name='Promotions'
        endpoint='promotions'
        orderField='createdAt'
        orderDir='DESC'
        headings={[
          { type: 'empty', name: 'ID', field: 'id', sort: true, width: 90 },
          { type: 'date-range', name: t('common.createdAt'), field: 'createdAt', sort: true, width: 120 },
          { type: 'text', name: t('promotion.title'), field: 'title', sort: true },
          { type: 'date-range', name: t('promotion.from'), field: 'from', sort: true, width: 120 },
          { type: 'date-range', name: t('promotion.to'), field: 'to', sort: true, width: 120 },
          { type: 'text', name: t('promotion.marketName'), field: 'market', sort: true, width: 120 },
          { type: 'text', name: t('promotion.languageName'), field: 'language', sort: true, width: 120 }
        ]}
        options={{}}
        renderRow={(row, idx) => (
          <TableRow key={idx}>
            {renderCells([
              { label: 'ID', value: row.id },
              { label: t('promotion.createdAt'), value: renderDate(row.createdAt, true) },
              { label: t('promotion.title'), breakWord: true, value: <Link href={`/promotions/edit/${row.id}`}>{row.title}</Link> },
              { label: t('promotion.from'), value: renderDate(row.promoLiveDate, true) },
              { label: t('promotion.to'), value: renderDate(row.promoEndDate, true) },
              { label: t('promotion.marketName'), value: row.market.name },
              { label: t('promotion.languageName'), value: row.language.name }
            ])}
            <ActionDropdown
              subject='Applications'
              name={'promotions'}
              buttons={[
                {
                  label: t('common.edit'),
                  name: 'edit-promotion',
                  handler: (): void => { navigate(`/promotions/edit/${row.id}`) },
                  icon: <EditOutlinedIcon color='info' />
                },
                {
                  label: t('common.delete'),
                  name: 'delete-promotion',
                  handler: (): void => { setElementToDelete(row) },
                  icon: <DeleteOutlineOutlinedIcon color='error' />
                }
              ]}
            />
          </TableRow>
        )}
      />
      {elementToDelete !== null
        ? <ConfirmDialog
          open={elementToDelete !== null}
          onAgree={handleDelete}
          onDisagree={handleHideDialog}
          question={t('dialogs.delete')}
        />
        : null}
    </>
  )
}

export default Promotions
