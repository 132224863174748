import React, { useEffect, useState } from 'react'
import AdvancedTable from 'components/AdvancedTable'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import ActionDropdown from 'components/ActionDropdown'
import { Backdrop, Box, Button, CircularProgress, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import { useTranslation } from 'react-i18next'
import type { Market } from 'types/Market'
import { Can, AbilityContext } from 'config/ability'
import { getTimezoneOffset } from 'date-fns-tz'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined'
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import { useForm, Controller, type FieldValues, type SubmitHandler } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosPost } from 'connectors/axiosPost'
import Checkbox from '@mui/material/Checkbox'
import SendIcon from '@mui/icons-material/Send'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useAlert } from 'context/AlertContext'
import { axiosDelete } from 'connectors/axiosDelete'
import ConfirmDialog from 'components/ConfirmDialog'
import { useAppContext } from 'context/AppContext/AppContext'
import { useAbility } from '@casl/react'
import { generateQueryParams } from 'utils/generateQueryParams'
import { renderCells } from 'utils/renderCells'
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined'
import type { AxiosResponse } from 'axios'
import type { Filters } from 'context/FiltersContext/FiltersContext.types'

const environments = [
  { code: 'DEV', url: 'localhost:7000' },
  { code: 'BETA', url: process.env.REACT_APP_WEB_BETA ?? '' },
  { code: 'STAGE', url: process.env.REACT_APP_WEB_STAGE ?? '' },
  { code: 'PROD', url: process.env.REACT_APP_WEB_PROD ?? '' }
]

const Markets = (): JSX.Element => {
  const queryClient = useQueryClient()
  const [isExportMode, setIsExportMode] = useState<boolean>(false)
  const { token, refreshToken, get2fa } = useAuthContext()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { changeMessage } = useAlert()
  const { handleRefetch, markets } = useAppContext()
  const [relationError, setRelationError] = useState<{ id: string | null, msg: string }>({ id: null, msg: '' })
  const [dataToMigrate, setDataToMigrate] = useState<{ marketIds?: Array<string | number> } | null>(null)
  const ability = useAbility(AbilityContext)

  interface SelectedValues extends FieldValues {
    marketIds: string[]
  }
  const methods = useForm<SelectedValues>({ defaultValues: { marketIds: [] } })

  const handleClearRelationError = (): void => { setRelationError({ id: null, msg: '' }) }

  const handleDownloadMarkets = async (filterToDownload: Filters): Promise<any> => {
    const params = generateQueryParams(filterToDownload)

    const url = `${process.env.REACT_APP_API_URL}/markets/export?${params.toString()}`

    const options = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    const res = await fetch(url, options)
    const blob = await res.blob()
    const file = window.URL.createObjectURL(blob)

    window.location.assign(file)
    setIsExportMode(false)
    void queryClient.refetchQueries(['markets-query'])
  }

  const { mutate: migrateMutate, data: migrateResponse, reset, isLoading: isMutationLoading } = useMutation(
    async ({ marketIds, environment }: { environment?: string, marketIds?: Array<string | number> }) => await axiosPost<string>(
      token,
      refreshToken,
      'markets/migrate',
      { marketIds, environment }
    ),
    {
      onSuccess: async (response) => {
        if (response !== undefined && typeof response !== 'string' && 'error' in response && response.error !== undefined) {
          changeMessage(response.error.data.message, 'error', reset)
        } else {
          if (response instanceof Array) {
            response.forEach(el => {
              const marketCode = markets !== undefined ? markets.data.find(market => market.id.toString() === el.marketId)?.code ?? el.marketId : el.marketId

              changeMessage(
                <div key={el.marketId}>{el.message !== '' ? el.message : t('common.success')}: {marketCode}</div>,
                el.message !== '' ? 'error' : 'success',
                reset)
            })
          }
          methods.resetField('marketIds')
          reset()
          void queryClient.refetchQueries(['markets-query'])
        }
      }
    })

  const { mutate, data: deleteResponse, reset: resetDelete } = useMutation(
    async ({ id, hard }: { id: string, hard?: boolean }) => await axiosDelete(token, refreshToken, `markets/${hard !== undefined && hard ? 'hard/' : ''}${id}`),
    {
      onSuccess: () => { void queryClient.refetchQueries(['markets-query']); handleRefetch() },
      onSettled: (data, _, { id }) => {
        if (data !== undefined && 'error' in data && data.error !== undefined && (Boolean(data.error.data.message.includes('Relation error')))) {
          setRelationError({ id, msg: data.error.data.message })
        }
      }
    })

  const { mutate: anonymize, reset: resetAnonymize } = useMutation(
    async ({ id, tfa }: { id: string, tfa: string }) => await axiosPost<AxiosResponse<string>>(token, refreshToken, `markets/anonymize/${id}`, { tfa }),
    {
      onSuccess: () => { void queryClient.refetchQueries(['markets-query']); handleRefetch() },
      onSettled: (data) => {
        if (data !== undefined && typeof data === 'string' && data === '') {
          changeMessage(t('common.success'), 'success', resetAnonymize)
        }
        if (data !== undefined && 'error' in data && data.error !== undefined) {
          changeMessage(data?.error?.data?.message, 'error', resetAnonymize)
        }
      }
    })

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (deleteResponse !== undefined && typeof deleteResponse !== 'string' && 'error' in deleteResponse && deleteResponse.error !== undefined && !(deleteResponse.error?.data.message.includes('Relation error'))) {
      changeMessage(deleteResponse?.error?.data?.message, 'error', resetDelete)
    }
    void queryClient.refetchQueries({ queryKey: ['all-markets'] })
  }, [deleteResponse])

  useEffect(() => {
    if (migrateResponse !== undefined && typeof migrateResponse !== 'string') {
      if ('error' in migrateResponse) {
        changeMessage(migrateResponse?.error?.data?.message, 'error', reset)
      }
    }
  }, [migrateResponse])

  const handleMigrate = (environment: string): void => {
    if (dataToMigrate !== null) {
      migrateMutate({ marketIds: dataToMigrate.marketIds, environment })
    }
    setDataToMigrate(null)
  }

  const onSubmit: SubmitHandler<SelectedValues> = (data) => {
    setDataToMigrate({ marketIds: data.marketIds })
  }

  const changeExportMode = (value: boolean): void => {
    setIsExportMode(value)
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4' component='h1' mb={2}>{t('navigation.markets')}</Typography>
        <Box>
          <Can I='migrate' a='Markets'>
            <Button variant='contained' form='markets-table-form' type='submit' color='warning' sx={{ mr: 1 }} startIcon={<SendIcon />}>{t('common.migrate')}</Button>
          </Can>
          <Can I='download' a='Markets'>
            <Button
              onClick={() => { setIsExportMode(true) }}
              type='button'
              variant='contained'
              color='info'
              sx={{ ml: 'auto', mr: 1 }}
              startIcon={<VerticalAlignBottomOutlinedIcon />}
            >
              {t('common.export')}
            </Button>
          </Can>
          <Can I='create' a='Markets'>
            <Button variant='contained' onClick={(): void => { navigate('/markets/add') }} startIcon={<AddBusinessOutlinedIcon />}>{t('common.add')}</Button>
          </Can>
        </Box>
      </Box>
      <AdvancedTable<Market>
        name='markets'
        endpoint='markets'
        formId='markets-table-form'
        changeExportMode={changeExportMode}
        formHandler={methods.handleSubmit(onSubmit)}
        isExportMode={isExportMode}
        onUsedFilters={async (filters) => {
          if (isExportMode) {
            await get2fa(async (tfa) => { await handleDownloadMarkets({ ...filters, tfa }) })
          }
        }}
        headings={[
          { type: 'empty', name: '', field: '', width: 74, hide: !ability.can('migrate', 'Markets') },
          { type: 'empty', name: 'ID', field: 'id', sort: true, width: 90 },
          { type: 'text', name: t('common.name'), field: 'name', sort: true, width: 130 },
          { type: 'options', name: t('common.languages'), field: '', width: 120 },
          { type: 'empty', name: t('market.timezone'), field: '', width: 120 }
        ]}
        options={{}}
        renderRow={(row, idx) => (
          <TableRow key={idx} sx={{ position: 'relative' }}>
            <Can I='migrate' a='Markets'>
              <TableCell>
                <Controller
                  name="marketIds"
                  control={methods.control}
                  render={({ field: { value, ref, ...field } }) => (
                    <Checkbox
                      {...field}
                      value={row.id}
                      inputRef={ref}
                      checked={value.includes(row.id.toString())}
                      onChange={(_, checked) => {
                        if (checked) {
                          field.onChange([...value, row.id.toString()])
                        } else {
                          field.onChange([...value].filter(e => e !== row.id.toString()))
                        }
                      }}
                      color="primary"
                      disableRipple
                    />
                  )}
                />
              </TableCell>
            </Can>
            {renderCells([
              { label: 'ID', value: row.id },
              {
                label: t('common.name'),
                value: (
                  <>
                    <strong>
                      <Can I='create' a='Markets' ><Link href={`/markets/edit/${row.id}`}>{row.name}</Link></Can>
                      <Can I='create' a='Markets' not ><Link href={`/applications?marketId=${row.id}`}>{row.name}</Link></Can>
                    </strong>
                    <br />
                    {row.description}</>
                )
              },
              { label: t('common.languages'), value: row.languages?.length > 0 ? row.languages?.map(el => el.name).join(', ') : 'N/A' },
              { label: t('market.timezone'), value: <>{row.timeZone}<div>(GMT {getTimezoneOffset(row.timeZone) / 3600000}h)</div></> }
            ])}
            <ActionDropdown
              subject='Markets'
              name={row.name}
              buttons={[
                {
                  label: t('common.edit'),
                  name: 'edit-market',
                  handler: (): void => { navigate(`/markets/edit/${row.id}`, { state: { ...row } }) },
                  icon: <EditOutlinedIcon color='info' />
                },
                {
                  label: t('common.anonymize'),
                  name: 'anonymize-market',
                  handler: (): void => {
                    void get2fa(async (tfa) => { anonymize({ id: row.id.toString(), tfa }) })
                  },
                  icon: <KeyOutlinedIcon color='info' />
                }
              ]}
              deleteHandler={() => { mutate({ id: row.id.toString() }) }}
            />
          </TableRow>
        )}
      />
      <Dialog onClose={() => { setDataToMigrate(null) }} open={dataToMigrate?.marketIds !== undefined && dataToMigrate.marketIds.length > 0}>
        <DialogTitle>{t('dialogs.wchichEnv')}</DialogTitle>
        <List sx={{ pt: 0 }}>
          {environments.map((environment) => process.env.REACT_APP_ENVIRONMENT !== environment.code
            ? <ListItem key={environment.code} disableGutters>
              <ListItemButton onClick={() => { handleMigrate(environment.url) }}>
                <ListItemText primary={environment.code} secondary={environment.url} />
              </ListItemButton>
            </ListItem>
            : null)}
        </List>
      </Dialog>
      <ConfirmDialog
        open={relationError.id !== null}
        onAgree={() => { if (relationError.id !== null) { mutate({ id: relationError.id, hard: true }); handleClearRelationError() } }}
        onDisagree={handleClearRelationError}
        question={relationError.msg}
        confirmText={t('common.delete')}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={isMutationLoading}
      >
        <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
          <CircularProgress color='warning' />
          <Typography fontFamily='inherit' fontWeight={800}>{t('market.migrating')}</Typography>
        </Box>
      </Backdrop>
    </>
  )
}

export default Markets
