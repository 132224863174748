import React, { useEffect } from 'react'
import { Box, Button, Card, MenuItem } from '@mui/material'
import Input from 'components/Input'
import FileUpload from 'components/FileUpload'
import { imageExt } from 'config/extensions'
import type { Market } from 'types/Market'
import Select from 'components/Select'
import { useTranslation } from 'react-i18next'
import { axiosPost } from 'connectors/axiosPost'
import { useAuthContext } from 'context/AuthContext'
import { useMutation } from '@tanstack/react-query'
import { useAlert } from 'context/AlertContext'
import type { Promotion } from '../Promotions.types'
import { useHandleForm } from 'hooks/useHandleForm'
import { format } from 'date-fns'
import type { UseHandleFormReturnType } from 'hooks/useHandleForm/useHandleForm'

interface PromotionFormFields {
  title: string
  holdingPageDate: Date
  promoLiveDate: Date
  promoEndDate: Date
  closedPageEndDate: Date
  personalDataDeletedBy: Date
  file: File
  marketId: string
  languageId: string
  url: string
}

export interface CreatePromotionProps {
  market?: Market
  selectedMarketId: string
}

const CreatePromotion = ({ selectedMarketId, market }: CreatePromotionProps): JSX.Element => {
  const { t } = useTranslation()

  const { Provider, control, setValue, onHandleSubmit } = useAddPromotion(selectedMarketId)

  useEffect(() => {
    setValue('marketId', selectedMarketId)
    setValue('languageId', '')
  }, [selectedMarketId])

  return (
    <Box>
      <Provider>
        <form onSubmit={onHandleSubmit}>
          <Card sx={{ p: 4 }}>
            <Input control={control} label='Title' name='title' type='text' />
            <Box display='flex' flexWrap='wrap' gap={4}>
              <Box flexBasis='300px' flexGrow={1}><Input control={control} label='holdingPageDate' name='holdingPageDate' type='datetime' /></Box>
              <Box flexBasis='300px' flexGrow={1}><Input control={control} label='promoLiveDate' name='promoLiveDate' type='datetime' /></Box>
            </Box>
            <Box display='flex' flexWrap='wrap' gap={4}>
              <Box flexBasis='300px' flexGrow={1}><Input control={control} label='promoEndDate' name='promoEndDate' type='datetime' /></Box>
              <Box flexBasis='300px' flexGrow={1}><Input control={control} label='closedPageEndDate' name='closedPageEndDate' type='datetime' /></Box>
            </Box>
            <Box display='flex' flexWrap='wrap' gap={4}>
              <Box flexBasis='300px' flexGrow={1}><Input control={control} label='personalDataDeletedBy' name='personalDataDeletedBy' type='datetime' /></Box>
            </Box>

            <Box display='flex' flexWrap='wrap' gap={4}>
              <Box flexBasis='300px' flexGrow={1}>
                <Select control={control} renderFunction={(el) => <MenuItem value={el.id}>{el.name}</MenuItem>} elements={market?.languages ?? []} label='Language' name='languageId' />
              </Box>
              <Box flexBasis='300px' flexGrow={1}><Input control={control} label='Url' name='url' type='text' /></Box>
            </Box>

            <Box>
              <FileUpload control={control} extensions={imageExt} label='Banner' name='file' />
            </Box>
          </Card>
          <Box mt={2} display='flex' justifyContent='flex-end'>
            <Button type='submit' variant='contained' color='success'>
              {t('common.save')}
            </Button>
          </Box>
        </form>
      </Provider>
    </Box>
  )
}

export default CreatePromotion

const useAddPromotion = (selectedMarketId?: string): UseHandleFormReturnType<PromotionFormFields> => {
  const { t } = useTranslation()
  const { token, refreshToken } = useAuthContext()
  const { changeMessage } = useAlert()

  const { mutate, data } = useMutation(
    async (promotionData: FormData) => await axiosPost<Promotion>(token, refreshToken, 'promotions/add', promotionData))

  useEffect(() => {
    if ((data != null) && data instanceof Object && 'error' in data) {
      changeMessage(t('common.failure'), 'error', () => { })
    }
    if ((data != null) && data instanceof Object && !('error' in data) && 'id' in data) {
      changeMessage(t('common.success'), 'success', () => { })
    }
  }, [data])

  return useHandleForm({
    defaultValues: {
      title: '',
      holdingPageDate: new Date(),
      promoLiveDate: new Date(),
      promoEndDate: new Date(),
      closedPageEndDate: new Date(),
      personalDataDeletedBy: new Date(),
      marketId: selectedMarketId,
      languageId: '',
      url: ''
    },
    validate: Yup => Yup.object().shape({
      title: Yup.string().required(t('forms.required', { field: t('promotion.title') }) ?? 'Required'),
      file: Yup.mixed(),
      holdingPageDate: Yup.date().required(t('forms.required', { field: t('promotion.from') }) ?? 'Required'),
      promoLiveDate: Yup.date().required(t('forms.required', { field: t('promotion.to') }) ?? 'Required'),
      promoEndDate: Yup.date().required(t('forms.required', { field: t('promotion.to') }) ?? 'Required'),
      closedPageEndDate: Yup.date().required(t('forms.required', { field: t('promotion.to') }) ?? 'Required'),
      personalDataDeletedBy: Yup.date().required(t('forms.required', { field: t('promotion.to') }) ?? 'Required'),
      marketId: Yup.string().required(t('forms.required', { field: t('promotion.marketName') }) ?? 'Required'),
      languageId: Yup.string().required(t('forms.required', { field: t('promotion.languageName') }) ?? 'Required'),
      url: Yup.string().required(t('forms.required', { field: t('promotion.url') }) ?? 'Required')
        .test({
          test: (value) => {
            const urlRegex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/

            return urlRegex.test(value)
          },
          message: t('forms.badUrlFormat') ?? 'Bad url format',
          name: 'urlFormat'
        })
    }),
    onValidationSubmit: (data) => {
      const fd = new FormData()
      Object.entries(data).forEach(([fieldKey, fieldValue]) => {
        if (fieldValue instanceof File) {
          fd.append('file', fieldValue)
        } else if (fieldValue instanceof Date) {
          const formatString = 'yyyy-MM-dd HH:mm:ss'
          fd.append(fieldKey, format(fieldValue, formatString))
        } else {
          fd.append(fieldKey, fieldValue.toString())
        }
      })
      mutate(fd)
    }
  })
}
