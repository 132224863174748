import React from 'react'
import { type RouteObject } from 'react-router-dom'

// import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined'
// import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined'
// import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
// import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined'
import TranslateIcon from '@mui/icons-material/Translate'
import LogoutIcon from '@mui/icons-material/Logout'
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PersonIcon from '@mui/icons-material/Person'

import ForgotPassword from 'pages/ForgotPassword'
import MarketGate from 'components/MarketGate'
import Layout from 'components/Layout/Layout'
// import Dashboard from 'pages/Dashboard'
import Account from 'pages/Account'
import SignIn from 'pages/SignIn'
import Logs from 'pages/Logs'

import UserForm from 'pages/Users/Form'
import Users from 'pages/Users'

// import PrizeEditForm from 'pages/PrizesGroups/EditForm/PrizeEditForm'
// import PrizeAddForm from 'pages/PrizesGroups/AddForm/PrizeAddForm'
import ChangeUserDataForm from 'pages/ChangeUserDataForm'
// import Prizes from 'pages/PrizesGroups/Prizes'
// import PrizesGroups from 'pages/PrizesGroups'

import MarketEditForm from 'pages/Markets/Form/MarketEditForm'
import MarketAddForm from 'pages/Markets/Form/MarketAddForm'
import Markets from 'pages/Markets'

// import ApplicationDetails from 'pages/Applications/Details/ApplicationDetails'
// import ApplicationEditForm from 'pages/Applications/Form/ApplicationEditForm'
// import ApplicationAddForm from 'pages/Applications/Form/ApplicationAddForm'
// import Applications from 'pages/Applications'

// import GlobalBarcodesForm from 'pages/Barcodes/GlobalBarcodesForm'
// import BarcodesForm from 'pages/Barcodes/Form/BarcodesForm'
// import BarcodesTabs from 'pages/Barcodes/BarcodesTabs'

import MarketLanguages from 'pages/MarketLanguages'

import Translations from 'pages/Translations'

// import ImportPrizes from 'pages/ImportPrizes/ImportPrizes'

import { handleLogout } from 'utils/handleLogout'

import type { Subjects } from './ability'
import Promotions from 'pages/Promotions'
import CreatePromotion from 'pages/Promotions/add/Form'
import EditPromotion from 'pages/Promotions/edit/Form'
// import type { ApplicationFormProps } from 'pages/Applications/Form/ApplicationForm.types'

export const routerOptions: RouteObject[] = [
  {
    path: '/',
    element: <SignIn />
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />
  },
  {
    path: '/dashboard',
    element: (
      <Layout>
        <Promotions />
      </Layout>
    )
  },
  {
    path: '/account',
    element: (
      <Layout>
        <Account />
      </Layout>
    )
  },
  {
    path: '/logs',
    element: (
      <Layout>
        <Logs />
      </Layout>
    )
  },
  {
    path: '/promotions',
    element: (
      <Layout>
        <Promotions />
      </Layout>
    )
  },
  {
    path: '/promotions/add',
    element: (
      <Layout>
        <MarketGate key='add-promotion' titleTranslationCode='navigation.addPromotion' Component={CreatePromotion} componentProps={{}} />
      </Layout>
    )
  },
  {
    path: '/promotions/edit/:id',
    element: (
      <Layout>
        <MarketGate key='edit-promotion' titleTranslationCode='navigation.editPromotion' Component={EditPromotion} componentProps={{}} />
      </Layout>
    )
  },
  {
    path: '/market-languages',
    element: (
      <Layout>
        <MarketLanguages />
      </Layout>
    )
  },
  {
    path: '/change-account-data/:field',
    element: (
      <Layout>
        <ChangeUserDataForm />
      </Layout>
    )
  },
  {
    path: '/translations',
    element: (
      <Layout>
        <MarketGate key='translations' componentProps={{}} Component={Translations} titleTranslationCode='navigation.translations' />
      </Layout>
    )
  },
  {
    path: '/users',
    element: (
      <Layout>
        <Users />
      </Layout>
    )
  },
  {
    path: '/users/edit/:id',
    element: (
      <Layout>
        <UserForm type='edit' />
      </Layout>
    )
  },
  {
    path: '/users/add',
    element: (
      <Layout>
        <UserForm type='add' />
      </Layout>
    )
  },
  {
    path: '/markets',
    element: (
      <Layout>
        <Markets />
      </Layout>
    )
  },
  {
    path: '/markets/edit/:id',
    element: (
      <Layout>
        <MarketEditForm />
      </Layout>
    )
  },
  {
    path: '/markets/add',
    element: (
      <Layout>
        <MarketAddForm />
      </Layout>
    )
  },
  {
    path: '/markets',
    element: (
      <Layout>
        <Markets />
      </Layout>
    )
  }
  // {
  //   path: '/prizes-groups',
  //   element: (
  //     <Layout>
  //       <PrizesGroups />
  //     </Layout>
  //   )
  // },
  // {
  //   path: '/prizes',
  //   element: (
  //     <Layout>
  //       <Prizes />
  //     </Layout>
  //   )
  // },
  // {
  //   path: '/prizes/add',
  //   element: (
  //     <Layout>
  //       <PrizeAddForm />
  //     </Layout>
  //   )
  // },
  // {
  //   path: '/prizes/edit/:id',
  //   element: (
  //     <Layout>
  //       <PrizeEditForm />
  //     </Layout>
  //   )
  // },
  // {
  //   path: '/barcodes',
  //   element: (
  //     <Layout>
  //       <BarcodesTabs />
  //     </Layout>
  //   )
  // },
  // {
  //   path: '/barcodes/add',
  //   element: (
  //     <Layout>
  //       <BarcodesForm />
  //     </Layout>
  //   )
  // },
  // {
  //   path: '/global-barcodes/add',
  //   element: (
  //     <Layout>
  //       <GlobalBarcodesForm />
  //     </Layout>
  //   )
  // },
  // {
  //   path: '/applications',
  //   element: (
  //     <Layout>
  //       <Applications />
  //     </Layout>
  //   )
  // },
  // {
  //   path: '/applications/:id',
  //   element: (
  //     <Layout>
  //       <ApplicationDetails />
  //     </Layout>
  //   )
  // },
  // {
  //   path: '/applications/add',
  //   element: (
  //     <Layout>
  //       <MarketGate<ApplicationFormProps> key='translations' componentProps={{ type: 'add', selectedMarketId: '' }} Component={ApplicationAddForm} titleTranslationCode='navigation.addApplication' />
  //     </Layout>
  //   )
  // },
  // {
  //   path: '/applications/edit/:id',
  //   element: (
  //     <Layout>
  //       <ApplicationEditForm type='edit' selectedMarketId='' />
  //     </Layout>
  //   )
  // },
  // {
  //   path: '/import-prizes',
  //   element: (
  //     <Layout>
  //       <MarketGate key='translations' onlyFlow='pd' componentProps={{}} Component={ImportPrizes} titleTranslationCode='navigation.importPrizes' />
  //     </Layout>
  //   )
  // }
]

interface NavItem {
  id: string
  name: string
  href: string
  icon: JSX.Element
  readSubject: Subjects
}

interface NavSection {
  id: string
  items: NavItem[]
}

export const navitems: NavSection[] = [
  {
    id: '0001',
    items: [
      // { id: '0001-0000', name: 'navigation.dashboard', href: '/dashboard', icon: <HomeOutlinedIcon />, readSubject: 'Dashboard' },
      // { id: '0001-0001', name: 'navigation.applications', href: '/applications', icon: <FormatListNumberedOutlinedIcon />, readSubject: 'Applications' },
      { id: '0001-0001', name: 'navigation.markets', href: '/markets', icon: <StoreOutlinedIcon />, readSubject: 'Markets' },
      { id: '0001-0002', name: 'navigation.promotions', href: '/promotions', icon: <LinkOutlinedIcon />, readSubject: 'Promotions' },
      { id: '0001-0003', name: 'navigation.translations', href: '/translations', icon: <TranslateIcon />, readSubject: 'Translations' },
      // { id: '0001-0004', name: 'navigation.prizes', href: '/prizes-groups', icon: <EmojiEventsOutlinedIcon />, readSubject: 'Prizes' },
      // { id: '0001-0005', name: 'navigation.barcodes', href: '/barcodes', icon: <QrCode2OutlinedIcon />, readSubject: 'Barcodes' },
      { id: '0001-0006', name: 'navigation.marketLanguages', href: '/market-languages', icon: <LanguageOutlinedIcon />, readSubject: 'Market Languages' },
      // { id: '0001-0007', name: 'navigation.prizeDraw', href: '/import-prizes', icon: <UploadFileOutlinedIcon />, readSubject: 'Import prizes' },
      { id: '0001-0008', name: 'navigation.users', href: '/users', icon: <PeopleAltOutlinedIcon />, readSubject: 'Users' }
    ]
  },
  {
    id: '0002',
    items: [
      { id: '0002-0001', name: 'navigation.logs', href: '/logs', icon: <ArticleOutlinedIcon />, readSubject: 'Logs' }
    ]
  }
]

export const accountItems = [
  {
    id: 'account-menu-0001',
    items: [
      { id: 'account-item-1001', name: 'navigation.account', href: '/account', icon: <PersonIcon /> }
    ]
  },
  {
    id: 'account-menu-0002',
    items: [
      { id: 'account-item-2003', name: 'navigation.logout', href: '/', handler: handleLogout, icon: <LogoutIcon /> }
    ]
  }
]
