import React from 'react'
import { MenuItem, Grid } from '@mui/material'
import Select from 'components/Select'
import Input from 'components/Input'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import type { UserFormValues, UserFormFieldsProps, Role } from './UserForm.types'
import type { Market } from 'types/Market'
import { useAppContext } from 'context/AppContext/AppContext'

const UserFormFields = ({ isAddForm, onHandleSubmit, marketsData, roles, defaultUserData }: UserFormFieldsProps): JSX.Element => {
  const { t } = useTranslation()
  const { markets } = useAppContext()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('forms.required', { field: t('user.name') }) ?? 'Required'),
    email: Yup.string()
      .required(t('forms.required', { field: t('signIn.email') }) ?? 'Required')
      .email(t('forms.invalid', { field: t('signIn.email') }) ?? 'Invalid'),
    password: Yup.string()
      .required(t('forms.required', { field: t('signIn.password') }) ?? 'Required')
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{16,})/,
        t('forms.passwordValidation') ?? 'Bad Validation'
      )
  })

  const regx = /\d/g
  const generateRandomPassword = (): string => window.crypto.getRandomValues(new BigUint64Array(2)).reduce(
    (prev, curr, index) => (
      (index === 0) ? prev : prev.toString()
    ) + (
      ((index % 2) !== 0) ? curr.toString(36).toUpperCase().replace(regx, key => '.,:;-_()=*'.charAt(+key)) : curr.toString(36)
    ), ''
  )

  const defaultValues: UserFormValues = {
    email: defaultUserData?.email ?? '',
    name: defaultUserData?.name ?? '',
    roleId: defaultUserData?.roleId ?? 1,
    password: generateRandomPassword(),
    marketsIds: markets !== undefined ? markets.data.filter(market => defaultUserData?.marketPermissions?.includes(market.id)) : [],
    isActive: defaultUserData?.isActive ?? false
  }

  const { handleSubmit, control, watch } = useForm<UserFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <form id='add-user-form' onSubmit={handleSubmit(onHandleSubmit)} noValidate>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <Input<UserFormValues>
            textInputProps={{ autoFocus: true }}
            name='email'
            label={t('user.email')}
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input<UserFormValues>
            name='name'
            label={t('user.name')}
            control={control}
          />
        </Grid>
        {isAddForm
          ? (<Grid item xs={12} md={6}>
            <Input<UserFormValues>
              name='password'
              label={t('user.password')}
              textInputProps={{ type: 'text' }}
              control={control}
            />
          </Grid>)
          : null}
        <Grid item xs={12} md={6}>
          <Select<Role, UserFormValues>
            control={control}
            label={t('user.role')}
            name='roleId'
            elements={roles}
            renderFunction={(role) => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            )}
          />
        </Grid>
        {![1, 2, 4].includes(watch('roleId'))
          ? <Grid item xs={12} md={6}>
            <Select<Market, UserFormValues>
              control={control}
              withText
              label={t('user.market')}
              name='marketsIds'
              getOptionLabel={(value) => value.name}
              elements={marketsData}
              multiple
              withTextProps={{
                isOptionEqualToValue: (option, value) => option.name === value.name
              }}
              renderOption={(props, market) => (
                <MenuItem {...props} value={market.id}>
                  {market.name}
                </MenuItem>
              )}
            />
          </Grid>
          : null}
        <Grid item xs={12} md={6}>
          <Input<UserFormValues>
            name='isActive'
            type='switch'
            label='Is Active'
            control={control}
          />
        </Grid>
      </Grid>

      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}> */}
      {/*  <Button */}
      {/*    type='submit' */}
      {/*    color='success' */}
      {/*    variant='contained' */}
      {/*    sx={{ ml: 2 }} */}
      {/*  > */}
      {/*    {t(isAddForm ? 'common.add' : 'common.change')} */}
      {/*  </Button> */}
      {/* </Box> */}
    </form>
  )
}

export default UserFormFields
