import React, { useEffect } from 'react'
import TableRow from '@mui/material/TableRow'
import AdvancedTable from 'components/AdvancedTable'
import ActionDropdown from 'components/ActionDropdown'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { Language } from 'types/Language'
import RequestStatus from 'components/RequestStatus'
import { axiosDelete } from 'connectors/axiosDelete'
import { axiosPost } from 'connectors/axiosPost'
import { useAppContext } from 'context/AppContext/AppContext'
import { Typography } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { useAlert } from 'context/AlertContext'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import { renderCells } from 'utils/renderCells'

const MarketLanguages = (): JSX.Element => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { changeMessage } = useAlert()
  const { markets } = useAppContext()
  const { token, refreshToken } = useAuthContext()
  const { mutate: chooseDefault } = useMutation(
    async ({ id, marketId }: { id: number, marketId: number }) => await axiosPost<Language[]>(token, refreshToken, 'market-languages/change-default', {
      id: id.toString(),
      marketId: marketId?.toString()
    }), {
      onSuccess: (response, id) => {
        if (response !== undefined && 'data' in response) {
          void queryClient.refetchQueries(['markets-languages-query'])
        }
      }
    })

  const { mutate, data: deleteResponse, reset } = useMutation(async (id: number) => await axiosDelete(token, refreshToken, `market-languages/${id}`), {
    onSuccess: (response, id) => {
      if (response !== undefined && 'data' in response) {
        void queryClient.refetchQueries(['markets-languages-query'])
      }
    }
  })

  useEffect(() => {
    if (deleteResponse !== undefined && 'error' in deleteResponse) {
      changeMessage(deleteResponse?.error?.data?.message, 'error', reset)
    }
  }, [deleteResponse])

  if (markets === undefined) {
    return <RequestStatus data={markets} />
  }

  return (
    <>
      <Typography variant='h4' component='h1' mb={2}>{t('navigation.marketLanguages')}</Typography>
      <AdvancedTable<Language>
        name='markets-languages'
        endpoint='market-languages'
        headings={[
          { type: 'empty', name: 'ID', field: 'id', sort: true, width: 90 },
          { type: 'text', name: t('common.name'), field: 'name', sort: true },
          { type: 'text', name: t('common.code'), field: 'code', sort: true, width: 120 },
          { type: 'options', name: t('singleField.market'), field: 'marketId', sort: true, width: 150 },
          { type: 'empty', name: t('common.default'), field: 'default', sort: true, width: 140 },
          { type: 'empty', name: t('common.actions'), field: '', width: 90 }
        ]}
        options={{
          marketId: markets.data.map(el => ({ id: el.id.toString(), value: el.name })).sort((a, b) => a.value.localeCompare(b.value))
        }}
        renderRow={(row, idx) => (
          <TableRow key={idx}>
            {renderCells([
              { label: 'ID', value: row.id },
              { label: t('common.name'), value: row.name },
              { label: t('common.code'), value: row.code },
              { label: t('singleField.market'), value: row.market.name },
              { label: t('common.default'), value: row.default ? t('common.default') : '' }
            ])}
            <ActionDropdown
              subject='Market Languages'
              name={row.name}
              deleteHandler={() => { mutate(row.id) }}
              buttons={[
                {
                  name: 'setDefault',
                  label: t('common.setDefault'),
                  handler: () => { chooseDefault({ id: row.id, marketId: row.marketId }) },
                  icon: <EditOutlinedIcon color='info' />
                }
              ]}
            />
          </TableRow>
        )
        }
      />
    </>
  )
}

export default MarketLanguages
