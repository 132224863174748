import React from 'react'
import Modal from '@mui/material/Modal'
import CircularProgress from '@mui/material/CircularProgress'

const PageLoader = (): JSX.Element => {
  return (
    <Modal
      open={true}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <CircularProgress color='secondary' size={80} />
    </Modal>
  )
}

export default PageLoader
