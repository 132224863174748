import React from 'react'
import RequestStatus from 'components/RequestStatus'
import type { AvailableTranslation } from 'types/AvailableTranslation'
import type { TranslationsGroupViewProps } from './Translations.types'
import TranslationsForm from './TranslationsForm'
import useQueryGet from 'hooks/useQueryGet'

const TranslationsGroupView = ({ selectedGroup, selectedMarket, selectedLang }: TranslationsGroupViewProps): JSX.Element => {
  const { data, isLoading } = useQueryGet<AvailableTranslation[]>({
    queryKey: ['translation-group-data', selectedGroup],
    endpoint: `translations/available/${selectedGroup}?marketId=${selectedMarket}`,
    queryOptions: { cacheTime: 0 }
  })

  const {
    data: translationsData, isLoading: isTranslationsLoading
  } = useQueryGet<Record<string, string>>({
    queryKey: ['translations-data', selectedMarket, selectedLang, selectedGroup],
    endpoint: `translations?languageId=${selectedLang.id}&groupId=${selectedGroup}`,
    queryOptions: { cacheTime: 0 }
  })

  if (data === undefined || 'error' in data) {
    return <RequestStatus data={data} isLoading={isLoading} />
  }

  if (translationsData === undefined || 'error' in translationsData) {
    return <RequestStatus data={translationsData} isLoading={isTranslationsLoading} />
  }

  return (
    <TranslationsForm selectedLang={selectedLang} availableTranslations={data} translations={translationsData} />
  )
}

export default TranslationsGroupView
