import { type Control } from 'react-hook-form'
import React from 'react'
import Input from 'components/Input'
import type { Continent } from 'types/Continent'
import type { HandleSubmitProps, Language } from './MarketForm.types'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Select from 'components/Select'
import { type CountryType, countries } from 'config/countries'
import { timezones } from 'config/timezones'

const Config = ({ control, continents, languagesArray, isAddForm }: { isAddForm: boolean, control: Control<HandleSubmitProps, any>, languagesArray: Language[], continents: Continent[] }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Box>
      <Select<CountryType, HandleSubmitProps>
        control={control}
        name='name'
        withText
        label={t('market.country')}
        elements={[...countries, { code: '', label: '' }]}
        withTextProps={{
          isOptionEqualToValue: (option, value) => {
            if (typeof value === 'string') {
              return option.label === value
            }
            return option.label === value.label
          },
          disabled: !isAddForm,
          renderOption: (props, option) => (
            <MenuItem component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} value={option.label} {...props}>
              <img loading='lazy' width='20' src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`} alt='' />
              {option.label} ({option.code})
            </MenuItem>
          )
        }}

      />
      <Input<HandleSubmitProps>
        name='description'
        label={t('market.description')}
        control={control}
        textInputProps={{ multiline: true }}
      />
      <Select<Continent, HandleSubmitProps>
        control={control}
        name='groupId'
        label={t('market.group')}
        elements={continents}
        renderFunction={(continent) => (
          <MenuItem key={continent.id} value={continent.id}>
            {continent.name}
          </MenuItem>
        )}
      />
      <Select<Language, HandleSubmitProps>
        control={control}
        name='languages'
        label={t('market.language')}
        multiple
        withText
        withTextProps={{
          isOptionEqualToValue: (option, value) => {
            if (typeof value === 'string') {
              return option.name === value
            }
            return option.name === value.name
          },
          disabled: !isAddForm
        }}
        getOptionLabel={(language) => language.name}
        elements={languagesArray}
      />
      <Select<string, HandleSubmitProps>
        control={control}
        withText
        getOptionLabel={(value) => value}
        name='timeZone'
        withTextProps={{ disabled: !isAddForm }}
        label={t('market.timezone')}
        elements={[...timezones, '']}
      />
    </Box>
  )
}

export default Config
