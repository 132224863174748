
import React, { type ReactNode } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { type DeepPartial, FormProvider, useForm, type UseFormReturn } from 'react-hook-form'
import * as Yup from 'yup'

export interface UseHandleFormReturnType<T extends object> extends UseFormReturn<T, any> {
  Provider: ({ children }: { children: ReactNode }) => JSX.Element
  onHandleSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}

export interface UseHandleFormParamsType<T extends object> {
  defaultValues: DeepPartial<T>
  validate: (yup: typeof Yup) => Yup.ObjectSchema<T>
  onValidationSubmit: (data: T, touched: Partial<T>) => void
}

export const useHandleForm = <T extends object>({ defaultValues, validate, onValidationSubmit }: UseHandleFormParamsType<T>): UseHandleFormReturnType<T> => {
  const form = useForm<T>({
    defaultValues,
    resolver: yupResolver(validate(Yup))
  })

  const onHandleSubmit = form.handleSubmit((submittedData) => {
    let touched: Partial<T> = {}

    Object.entries(submittedData).forEach(([fieldKey, fieldValue]) => {
      if (fieldKey in form.formState.touchedFields) {
        touched = {
          ...touched,
          [fieldKey]: fieldValue
        }
      }
    })

    onValidationSubmit(submittedData, touched)
  })

  return {
    Provider: ({ children }: { children: ReactNode }): JSX.Element => (
      <FormProvider {...form}>
        {children}
      </FormProvider>
    ),
    ...form,
    onHandleSubmit
  }
}
