import React, { useEffect } from 'react'
import type { AddUserRequestBody, EditProps, Role, UpdateUserRequestBody, UserFormValues } from './UserForm.types'
import { useMutation } from '@tanstack/react-query'
import UserFormFields from './UserFormFields'
import { useTranslation } from 'react-i18next'
import { axiosPost } from 'connectors/axiosPost'
import { type User } from 'types/User'
import { useAppContext } from 'context/AppContext/AppContext'
import { useNavigate, useParams } from 'react-router-dom'
import RequestStatus from 'components/RequestStatus'
import { Button, Card, CardHeader, CardContent, Typography, CardActions } from '@mui/material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import { useAlert } from 'context/AlertContext'
import useQueryGet from 'hooks/useQueryGet'
import { useAuthContext } from 'context/AuthContext/AuthContext'

const UserForm = ({ type }: EditProps): JSX.Element => {
  const { t } = useTranslation()
  const { markets } = useAppContext()
  const { token, refreshToken } = useAuthContext()
  const params = useParams()
  const isAddForm = type === 'add'
  const navigate = useNavigate()
  const { changeMessage } = useAlert()

  const { mutate: addUser, data: responseData, status, reset } = useMutation(
    async (user: AddUserRequestBody) => await axiosPost<User>(token, refreshToken, 'users', user),
    {
      onSuccess: (data) => {
        if (data !== undefined) {
          navigate('/users')
        }
      }
    })
  const { mutate: updateUser, data: updateResponseData, status: updateStatus, reset: resetUpdate } = useMutation(
    async (user: UpdateUserRequestBody) => await axiosPost<User>(token, refreshToken, `users/update/${params?.id ?? ''}`, user)
  )

  useEffect(() => {
    if (responseData !== undefined && 'error' in responseData) {
      changeMessage(responseData?.error?.data?.message, 'error', reset)
    }
    if (responseData !== undefined && status === 'success' && !('error' in responseData)) {
      changeMessage(t('forms.fieldCreated', { field: t('singleField.user') }), 'success', reset)
    }
  }, [responseData, status])

  useEffect(() => {
    if (updateResponseData !== undefined && 'error' in updateResponseData) {
      changeMessage(updateResponseData?.error?.data?.message, 'error', resetUpdate)
    }
    if (updateResponseData !== undefined && updateStatus === 'success' && !('error' in updateResponseData)) {
      changeMessage(t('forms.fieldUpdated', { field: t('singleField.user') }), 'success', resetUpdate)
    }
  }, [updateResponseData, updateStatus])

  const { data: rolesData } = useQueryGet<Role[]>({ queryKey: ['roles-for-users', token], endpoint: 'roles' })

  const { data: userData, isLoading: userDataLoading } = useQueryGet<User>({
    queryKey: [`user-data-${params.id ?? ''}`, token, params.id],
    endpoint: `users/${params?.id ?? ''}`,
    queryOptions: { enabled: params.id !== undefined }
  })

  const onHandleSubmit = async (data: UserFormValues): Promise<void> => {
    if (type === 'add') {
      const requestBody = {
        ...data,
        marketsIds: data.marketsIds.map(market => market.id)
      }
      addUser(requestBody)
    } else {
      const editBody = {
        email: data.email,
        name: data.name,
        roleId: data.roleId,
        isActive: data.isActive,
        marketsIds: data.marketsIds.map(market => market.id)
      }
      updateUser(editBody)
    }
  }

  if ((userData === undefined || 'error' in userData) && type === 'edit') {
    return <RequestStatus data={userData} isLoading={userDataLoading} />
  }

  return (
    <>
      <Typography variant='h4' component='h1' mb={2}>{t(isAddForm ? 'common.addField' : 'common.editField', { field: t('singleField.user') })}</Typography>
      <Card>
        <CardHeader title={<Button
          type='button'
          variant='outlined'
          onClick={() => { navigate('/users') }}
          startIcon={<ChevronLeft />}
        >
          {t('common.backListText')}
        </Button>}
          action={
            <Button
              type='submit'
              color='success'
              variant='contained'
              form='add-user-form'
              startIcon={<PersonAddAltOutlinedIcon />}
            >
              {t(isAddForm ? 'common.add' : 'common.change')}
            </Button>
          } />
        <CardContent>

          {markets !== undefined && rolesData !== undefined && !('error' in rolesData)
            ? <UserFormFields
              onHandleSubmit={onHandleSubmit}
              isAddForm={type === 'add'}
              marketsData={markets.data}
              roles={rolesData}
              defaultUserData={userData !== undefined && type === 'edit' && !('error' in userData) ? { ...userData } : undefined}
            />
            : null}
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type='submit'
            color='success'
            variant='contained'
            form='add-user-form'
            startIcon={<PersonAddAltOutlinedIcon />}
          >
            {t(isAddForm ? 'common.add' : 'common.change')}
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default UserForm
