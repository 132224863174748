import React, { useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import { useAuthContext } from 'context/AuthContext'
import { axiosPost } from 'connectors/axiosPost'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import PageLoader from 'components/PageLoader'
import { useAlert } from 'context/AlertContext'

type ImportPromotionsResponseData = Array<{
  marketId: number
  promotionName: string
  data: string | {
    market: string
    marketLanguages: string
  }
}> | undefined

export const ImportPromotions = (): JSX.Element => {
  const queryClient = useQueryClient()
  const [url, setUrl] = useState('')
  const { changeMessage } = useAlert()
  const { refreshToken, token } = useAuthContext()

  const { mutate, isLoading, data } = useMutation(async (selectedUrl: string) => await axiosPost<ImportPromotionsResponseData>(token, refreshToken, 'markets/save-promotions', { url: selectedUrl }))

  useEffect(() => {
    if (data && !('error' in data)) {
      data.forEach(el => {
        void queryClient.refetchQueries(['Promotions-query'])
        changeMessage('Success', 'success', () => { })
      })
    } else if (data && 'error' in data) {
      changeMessage('Something went wrong', 'error', () => { })
    }
  }, [data])

  const importData = async (): Promise<void> => {
    mutate(url)
  }

  return (
    <>
      <Box>
        <input value={url} onChange={e => { setUrl(e.target.value) }} />
        <Button onClick={importData}>Import</Button>
      </Box>
      {isLoading && <PageLoader />}
    </>
  )
}
