import React, { useState } from 'react'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import MuiTooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import type { TooltipProps } from './Tooltip.types'
import Box from '@mui/material/Box'

const Tooltip = ({ text, boxSx }: TooltipProps): JSX.Element => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = (): void => {
    setOpen(false)
  }

  const handleTooltipOpen = (): void => {
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box sx={boxSx}>
        <MuiTooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={text}
        >
          <IconButton onClick={handleTooltipOpen}>
            <InfoOutlinedIcon />
          </IconButton>
        </MuiTooltip>
      </Box>
    </ClickAwayListener>
  )
}

export default Tooltip
